<template>
  <div class="mainBox">
      <div class="wrap">
          <div class="isLogo"><img src="../assets/img/ic_logo.png" alt=""><span class="zi">高效易用 办公必备</span>
            <div class="isRouter" v-if="isShowArr.includes(ishost)">
              <div class="ishover" @click.stop="goRoute(0)">首页</div>
              <div class="ishover" @click.stop="goRoute(1)">关于公司</div>
              <div class="ishover" @click.stop="goRoute(2)">联系我们</div>
            </div>
        </div>
          <div class="rightBox">
            <div class="isNav"  v-for="(item,index) in isList" :key="index" >
            <!-- <div class="isNav"  v-for="(item,index) in isList" :key="index" :class="$route.name == item.name? 'active': ''" > -->
              <div>{{ item.title }}<span v-if="!(index == isList.length-1)">&nbsp; | &nbsp;</span></div>
              <!-- <div class="isUnderLine" :style="{'opacity':$route.name == item.name || $route.name == item.rediret? 1 : 0}"></div> -->
            </div>
          </div>
      </div>
  </div>
</template>
<script>
  export default {
    name: 'AllHead',
    data(){
      return {
        isList: [
          // {title: '首页',value:0,path: '/',name:'FirstPage',rediret:'FirstPage'},
          // {title: '在线动态',value:0,path: '/hotdry',name:'HotDry',rediret:'ismore'},
          // {title: '在线帮助',value:0,path: '/ishelp',name: 'IsHelp',rediret:'IsHelp'},
          {title: 'PDF转PPT ',value:0,path: '/ishelp',name: 'IsHelp',rediret:'IsHelp'},
          {title: '合并PDF ',value:0,path: '/ishelp',name: 'IsHelp',rediret:'IsHelp'},
          {title: '拆分PDF ',value:0,path: '/ishelp',name: 'IsHelp',rediret:'IsHelp'},
          {title: '加密PDF ',value:0,path: '/ishelp',name: 'IsHelp',rediret:'IsHelp'},
          {title: '解密PDF ',value:0,path: '/ishelp',name: 'IsHelp',rediret:'IsHelp'},
        ],
        ishost:'',
      }
    },
    mounted(){
      this.ishost = window.location.host
    },
    methods:{
      toPage(v){
        this.$router.push(this.isList[v].path)
      },

      goRoute(v){
        let aaa = ['/','isAbout','lxUs']
        this.$router.push(aaa[v])
      }
    }
  }
</script>
<style lang="scss" scoped>
  .mainBox {
    width: 100%;
    height: 70px;
    background-color: rgba(255,255,255,0.7);
    .wrap {
      width: 1180px;
      height: 100%;
      margin: 0 auto;
      position: relative;
      display: flex;
      justify-content: space-between;
      .isLogo {
        width: 600px;
        height: 100%;
        display: flex;
        justify-content: left;
        align-items: center;

        .isRouter {
          width: 200px;
          font-size: 14px;
          display: flex;
          justify-content: space-around;
          line-height: 28px;
          color: #5B5D61;
          font-weight: 600;

          .ishover {
            cursor: pointer;
          }
          // .ishover:hover {

          // }
        }

        img {
          width: 179px;
          height: 36px;
          object-fit: contain;
        }

        .zi {
          width: 160px;
          height: 28px;
          font-size: 14px;
          line-height: 28px;
          display: inline-block;
          margin-left: 10px;
          color: #5B5D61;
          font-weight: 600;
        }
      }
  
      .rightBox {
        width: 550px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .isNav {
          font-size: 18px;
          line-height: 22px;
          color: #5B5D61;
          cursor: pointer;
          // font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
          .isUnderLine {
            height: 3px;
            margin-top: 3px;
            background-color: var(--base_color);
          }

        }

        .active {
          color: var(--base_color);
        }
      }
    }

  }
</style>